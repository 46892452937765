<script setup>
</script>

<template>
  <v-layout class="rounded rounded-md">
    <v-app-bar
      flat
      color="text"
      class="main-header d-flex justify-center mb-0 pb-0"
      height="177px"
    >
      <img
        src="/static/images/ApplicationLogo.png"
        alt="Application Logo"
        height="129px"
        class="mx-auto"
      />
    </v-app-bar>

    <v-main
      class="guest-main main-content-block"
      style="min-height: 600px"
    >
      <slot name="noGutter"></slot>

      <v-row>
        <v-col cols="1"></v-col>
        <v-col cols="10">
          <Feedback></Feedback>
          <slot />
        </v-col>
        <v-col cols="1"></v-col>
      </v-row>
    </v-main>
  </v-layout>
</template>
<script scoped>
export default {
  data() {
    return {
      drawer: true,
      rail: true,
    }
  },
}
</script>
<style scoped>
    .main-header {
        border-bottom: 5px rgb(var(--v-theme-primary)) solid;
        border-top: 5px rgb(var(--v-theme-white)) solid;
    }
    .guest-main {
        background-color: rgb(var(--v-theme-white)) !important;
    }
    .main-content-block {
        margin-top: 139px !important;
        padding-top: 0px !important;
        border-bottom: 5px rgb(var(--v-theme-primary)) solid;
    }
</style>

<style>
    body {
        background: rgb(var(--v-theme-text)) !important;
    }
</style>
